import React from 'react'
import eventsOverviewStyles from './EventsOverview.module.css'
import { formatTimespan } from '../helper/DateFormatting'
import { getUpcomingEvents } from '../js/EventsHelper'

export default ({ events, withDescription }) => {
  const upcomingEvents = getUpcomingEvents(events)
  const colSpan = { gridColumn: '2 / 4' }

  if (!upcomingEvents.length) {
    return (
      <div className="text-center italic">
        Derzeit sind keine Termine bekannt.
      </div>
    )
  }
  return (
    <ul className={eventsOverviewStyles.wrapper + ' list-reset'}>
      <li
        className={`${eventsOverviewStyles.container} ${
          eventsOverviewStyles.header
        }`}
      >
        <span>Datum</span>
        {!withDescription && <span>Veranstaltung</span>}
        {!withDescription && <span>Ort</span>}
        {withDescription && <span>Beschreibung</span>}
      </li>
      {upcomingEvents.map(event => {
        const textCSS = event.cancelled ? 'line-through' : ''
        return (
          <li
            key={event.start + event.title}
            className={`${eventsOverviewStyles.container} my-4`}
          >
            <span className="text-grey md:text-black">
              <span className={textCSS}>
                {formatTimespan(event.start, event.end)}
              </span>
              {event.cancelled && (
                <span className="text-red font-bold ml-2">ABGESAGT!</span>
              )}
            </span>
            {!withDescription && (
              <span className={`${textCSS} font-bold md:font-normal`}>
                {event.title}
              </span>
            )}
            {!withDescription && (
              <span className={textCSS}>{event.location}</span>
            )}
            {withDescription && (
              <span style={colSpan} className={textCSS}>
                {event.description}
              </span>
            )}
          </li>
        )
      })}
    </ul>
  )
}
