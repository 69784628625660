import React from 'react'
import MvTextPage from '../../components/common/MvTextPage'
import Layout from '../../components/Layout'
import { graphql } from 'gatsby'
import MvImage from '../../components/common/MvImage'
import MvTextPageSection from '../../components/common/MvTextPageSection'
import { getUpcomingEvents } from '../../js/EventsHelper'
import EventsOverview from '../../components/EventsOverview'
import MvQuote from '../../components/common/MvQuote'

export default ({ data }) => {
  const vereinPage = {
    name: 'Verein',
    path: '/verein',
  }
  const events = data.eventsFrauentreff.edges.map(({ node }) => node)
  const upcomingEvents = getUpcomingEvents(events)
  return (
    <Layout>
      <MvTextPage title="Frauentreff" parent={vereinPage}>
        <MvTextPageSection>
          <p>
            Am Vereinsleben teilnehmen, sich kennen lernen, aus diesem Gedanken
            entstand der Frauentreff. Was ganz zaghaft begann, entwickelte sich
            zur megastarken Truppe. Bei unseren Treffen am 1. Freitag des Monats
            hat Geselligkeit oberste Priorität. Es gibt immer etwas zu erzählen
            und zu lachen. Auch bei den vielen Reisen, die wir mittlerweile
            gemeinsam unternommen haben.
          </p>

          <MvImage
            fluid={data.frauentreff.childImageSharp.fluid}
            caption="Frauentreff im Jahr 2011"
            className="my-8"
          />

          <p>
            Es sind Freundschaften entstanden, die wir nicht mehr missen
            möchten. Es ist auch selbstverständlich geworden, das Schöne mit dem
            Nützlichen zu verbinden, das heißt mindestens fünf Veranstaltungen
            des Vereins im Jahr vorzubereiten und zu unterstützen. Nicht nur die
            Musikerfrauen, auch Mitglieder und Eltern von Jungmusikern zeigen
            ihre Solidarität und stärken dem Verein den Rücken. Der Frauentreff
            ist in den vielen Jahren zusammengewachsen und wurde zu einer
            Bereicherung für den Verein.
          </p>
        </MvTextPageSection>

        <MvTextPageSection title="Termine" fullWidth>
          <EventsOverview events={upcomingEvents} withDescription />
        </MvTextPageSection>

        <MvTextPageSection title="34 Jahre Frauentreff – Rückblick">
          <h3 className="mb-4">von 1985 - 2019</h3>
          <div className="text-center text-grey mb-16">
            von <address className="inline">Hildegard Vogl</address>
          </div>
          <p>
            Einen Rückblick zur Erinnerung an die vergangenen Jahre will ich
            euch geben. Schön ist, dass wir so viele Jahre einen so tollen
            Zusammenhalt hatten und hoffentlich noch weitere schöne Jahre
            miteinander in bester Gesundheit verbringen können. Wie stand mal in
            unserer Homepage?
          </p>
          <MvQuote>
            Was so zaghaft begann, entwickelte sich zu einer megastarken Truppe.
            Das zeigt sich, was wir in den vergangen Jahren miteinander erlebt
            haben, nach dem Motto „Lasst uns ganz bewusst erkennen, was wir
            Frauen Freitzeit nennen, das Leben genießen und die Natur, neue
            Kräfte sammeln ohne Kur“.
          </MvQuote>
          <p>
            Unsere schönsten Erlebnisse waren unsere Ausflüge in die Schweiz. Ab{' '}
            <b>1987–99</b> sind wir jedes Jahr nach Küblis oft 2 x im Jahr
            gefahren. Irmgard hat uns die schöne Bergwelt zu allen Jahreszeiten
            näher gebracht und wunderschöne Touren mit uns unternommen. Immer in
            Erinnerung bleiben wird uns der Eichkatzlweg, Strelapass,
            Rinnhornbahn, Jakobshorn, Mobiltal. Wir sind die Götschnabahn rauf
            und mit der Parsenbahn runtergefahren. Mit der Pferdekutsche,
            zugedeckt mit einem Fell, sind wir oberhalb von Davos in eine
            wunderschöne Schneelandschaft gefahren. – usw. Wir waren dann
            nochmals <b>2002 + 2003</b> in Küblis und keiner kam ohne Nudeln
            oder Schokolade nach Hause. Wir sind dann neugierig auch auf andere
            Reiseziele geworden. Weitere Ausflüge waren. <b>1998</b> waren wir
            in Holland in Amsterdam, Grachtenfahrt, Keukenhof, Blumencorso{' '}
            <b>2000</b> Reise an die Rivera, Monacco, Nizza, San Remo{' '}
            <b>1996 + 2001</b> waren wir im Bayr. Wald, im Schwellhäusle,
            Forellenhof, Gläserne Scheune <b>2004</b> haben wir in Bärndorf
            gewohnt und haben einen Tagesausflug nach Prag unternommen. Auf der
            Rückfahrt haben wir in Kehlheim die Befreiungshalle Walhalla
            besichtigt und sind im Kloster Weltenburg eingekehrt, der
            vorgesehene Donaudurchbruch war leider verregnet <b>2005</b> sind
            wir mit dem eigenen Bus bei einem Traumwetter zur Bodenseeinsel
            Mainau gefahren. <b>2007</b> waren wir in Tübingen und sind
            Stocherkahn gefahren <b>2008</b> Allgäu, Marienbrücke Schloss
            Neuschwanstein, v.kleinen Walsertal nach Oberstdorf zum Söllereck
            hochgefahren und den Panoramaweg gelaufen nach Riezlern <b>2009</b>{' '}
            Bahnfahrt nach Lindau, sind in Friedrichshafen zum Seehasenfest, mit
            Feuerwerk auf dem Schiff <b>2010</b> Rüdesheim und Mainz,
            Schifffahrt auf dem Rhein <b>2011</b> Nürnberg, Felsengänge,
            Brombacher Stausee, Kutschfahrt in Dinkelsbühl, Nördlingen{' '}
            <b>2012</b> Zugspitze, Schloss Neuschwanstein, Kloster Ettal,
            Oberammergau, Lermoos <b>2013</b> Bad Herrenalb, Wanderung auf dem
            Klosterpfad, Thermenbesuch <b>2014</b> Bad Waldsee zum Seenachts-
            und Altstadtfest, Besuch Hofkäserei Spätzlesmuseum,Therme, Wanderung
            im Naherholungsgebiet Tannenbühl, Besuch bei der Mostbäuerin. Wir
            haben vieles auf dem Mostzügle gehört, auch über die Aroniapflanze,
            danach das interessante Vesper mit essbaren Deko- Blumen, in
            Begleitung mit Musik <b>2015</b> Ausflug an den Comer See mit
            Ausflüge nach Mailand, Como. Überfahrt mit der Fähre von Tremezzo
            nach Bellagio, Villa Carlotta, Tessin, <b>2015</b> Tagesfahrt zum
            Fellhorn mit Trientiner Bergsteigerchor <b>2016</b> Bad Wildbad,
            Baumwipfelpfad, Themenaustellung Kaltenbronn,Wanderung über
            Hochmoore am Wildsee vorbei zur Grünhütte mit den
            Riesenpfannenkuchen <b>2017</b> Heidelberg mit Schifffahrt,
            Stadtführung Altstadt <b>2018</b> Seebach zur Vollmersmühle zum
            „Lichtgang“, Mummelsee <b>2019</b> Obstblüte an den Bodensee, bei
            Überlingen Rundfahrt mit dem Apfelzügles Express
          </p>
          <p>
            Als Zusatzangebot stand auf dem Programm: <b>2012</b> Kroatien,
            Rundfahrt Istrien, Porec, Rovinij, Montovum <b>2013</b> Rimini,
            Venedig, Florenz, Assisi, Perugia, San Marino <b>2014</b> Spreewald,
            Bad Muskau, Park Branitz von Fürst Pückler, Dresden Wie ihr seht,
            haben wir was vorzuweisen, aber das ist noch längst nicht alles. I
            Wir waren im Zumhof bei der Nudelherstellung, Laufenmühle,
            Senfmanufaktur, machten Stadtführungen in Stuttgart und Schorndorf ,
            haben teilgenommen bei dem Jubiläum vom VFL mit Auftritt
            Spätzleslied und beim letzten Jubiläum vom Musikverein mit Fahrt auf
            einem geschmückten Festwagen mit dem Motto – „ohne ons got nix“ und
            haben einen Auftritt im Zelt gehabt verkleidet als Musiker. Im
            Friedrichsbau im Variete mit Carsten Speck und einmal auf dem
            Killesberg. Wir haben tausend und eine Nacht in Heslach besucht mit
            Bauchtanz. Besuch im Fernsehstudio bei SWR, wo wir eine
            Fernsehausstrahlung live mit Birgit Schrowange erlebten. Weiter
            hatten wir einen Selbstbehauptungskurs im MH, es kam ein
            polizeilicher Sicherheitsberater, mehrmals Theaterbrettle in
            Plüderhausen. Besichtigung Moschee Schorndorf und Bäckerei Maurer in
            Winnenden. Sektkellerei Kessler in Esslingen. Wir hatten eine
            Besichtigung bei der Stuttgarter Zeitung und Zeitungsverlag
            Waiblingen. Unsere seit vielen Jahren besuchten lustigen und schöne
            verschiedene Besenbesuche, z.B. sind wir den Besinnungsweg
            abgelaufen mit Einkehr beim Besen Lausterer in Schmiden, - usw. –
            Was auch sehr schön ist, sind beim Frauentreff immer die liebevoll
            gedeckten Tische und das dazugehörende Essen. Danke an all die
            Geburtstagskinder, die dies ermöglichen. Ganz persönlich bedanken
            möchte ich mich bei allen, die den Frauentreff unterstützten und
            weiterhin sich einbringen. Es sind Freundschaften entstanden und wir
            haben so viel miteinander erlebt, nicht nur bei der Mithilfe von
            Vereinsfesten und ich denke, das verbindet.
          </p>
          <p>Wir freuen uns über jeden Neuzugang!</p>
        </MvTextPageSection>
      </MvTextPage>
    </Layout>
  )
}

export const query = graphql`
  query {
    frauentreff: file(
      relativePath: { eq: "pages/verein/frauentreff2011.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    eventsFrauentreff: allEventsFrauentreffJson {
      edges {
        node {
          start
          description
        }
      }
    }
  }
`
