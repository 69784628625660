import moment from 'moment'
import 'moment/locale/de'

export function formatTimespan(start, end) {
  moment.locale('de')
  let separator = '-'
  const startDate = moment(start)
  const endDate = moment(end)
  const sameMonth = startDate.month() === endDate.month()
  const sameYear = startDate.year() === endDate.year()

  if (sameYear && sameMonth && startDate.date() + 1 === endDate.date()) {
    separator = '&'
  }

  if (!end) {
    return startDate.format('LL')
  }

  if (sameMonth && sameYear) {
    return `${startDate.format('D')}. ${separator} ${endDate.format('LL')}`
  }
  if (sameYear) {
    return `${startDate.format('D.M.')} ${separator} ${endDate.format(
      'D.M.YYYY'
    )}`
  }
  return `${startDate.format('D.M.YYYY')} ${separator} ${endDate.format(
    'D.M.YYYY'
  )}`
}
