import React, { useState } from 'react'
import GatsbyImage from 'gatsby-image'
import Lightbox from 'fslightbox-react'

export default ({
  fluid,
  caption,
  photographer,
  className,
  style,
  disableToggle = false,
}) => {
  const [toggler, setToggler] = useState(false)

  return (
    <div className={className + ' relative'}>
      <Lightbox toggler={toggler} sources={[fluid.src]} />

      <div onClick={() => !disableToggle && setToggler(!toggler)}>
        <GatsbyImage fluid={fluid} style={style} />
      </div>

      {photographer && (
        <span className="absolute pin-l pin-b -mb-5 mr-4 text-xs text-grey">
          Foto: {photographer}
        </span>
      )}

      {caption && (
        <span className="gradient px-2 py-1 absolute pin-r pin-b -mb-3 mr-4 text-xs md:text-base">
          {caption}
        </span>
      )}
    </div>
  )
}
